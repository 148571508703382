import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './header.css'

const Header = (props) => {
  return (
    <div data-role="Header" className={`header-header ${props.rootClassName} `}>
      <div className="header-container">
        <Link to="/" className="header-navlink">
          <h1 className="header-heading textSM">{props.heading1}</h1>
        </Link>
      </div>
      <a
        href="https://shops.genresnoiz.com/admin/"
        className="header-link"
      >
        <div className="header-container1">
          <span className="header-text">Авторизация</span>
        </div>
      </a>
    </div>
  )
}

Header.defaultProps = {
  rootClassName: '',
  heading1: 'SHOPS TG',
}

Header.propTypes = {
  rootClassName: PropTypes.string,
  heading1: PropTypes.string,
}

export default Header
