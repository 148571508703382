import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <div className="footer-container">
      <div className="footer-container1">
        <div className="footer-container2">
          <h1 className="footer-text textXL">Внимание</h1>
          <span className="footer-text1">
            Сервис абсолютно бесплатен на момент разработки
          </span>
        </div>
        <div className="footer-container3">
          <a
            href="https://shops.genresnoiz.com/admin/"
            className="footer-link button"
          >
            {props.button}
          </a>
        </div>
      </div>
      <div className="footer-container4"></div>
      <span className="footer-text2 textSM">Copyright © 2022. Genres Noiz</span>
    </div>
  )
}

Footer.defaultProps = {
  button: 'Попробовать',
}

Footer.propTypes = {
  button: PropTypes.string,
}

export default Footer
