import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>shops.genresnoiz.com</title>
        <meta property="og:title" content="shops.genresnoiz.com" />
      </Helmet>
      <div className="home-hero">
        <div className="home-bg"></div>
        <Header></Header>
        <div className="home-container1">
          <div className="home-container2">
            <h1 className="home-text">Ваш магазин начинается здесь</h1>
            <span className="home-text1">
              Уже сейчас вы можете создать свой интернет магазин в телеграмме
              без какого-либо программирования!
            </span>
          </div>
          <img
            alt="image"
            src="/front/app/playground_assets/gray-vector.svg"
            className="home-image"
          />
          <img
            alt="image"
            src="/front/app/playground_assets/white-vector.svg"
            className="home-image1"
          />
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Home
